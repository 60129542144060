<template>
  <div>
    <b-sidebar
      id="sidebar-right-site-ownership"
      ref="sidebar_site_ownership"
      :visible="showOpen"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="b-sidebar-body" style="max-height: calc(100vh - 155px); overflow-y: auto;">
          <!-- {{idsite}} -->
          <div class="ml-3 my-2">
            <p
              v-if="idsite.label"
              class="mb-0"
              style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
            >
              Project
            </p>
            <h4
              v-if="idsite.label"
              class=""
              style="font-weight: 700;"
            >
              {{ idsite.label }}
            </h4>
          </div>
          <hr v-if="idsite.label">
          <h3
            class="mb-0 pb-0 pt-2"
            style="font-weight: 700; color: #181E38 !important; padding: 40px;"
          >
            {{typeOwnership === true || typeOwnership === 'true' ? 'Claim site' : typeOwnership === false ? 'Site disconnected' : 'Claim site'}}
          </h3>
          <claim-ownership
            style="padding: 40px;"
            class="mt-0 pt-0 pb-0 container-body-config-claim"
            :name="idsite.name"
            :url.sync="idsite.domain"
            :key-site.sync="keyID"
            :img="idsite.imgSite"
            :siteProp="idsite"
            :claim-license.sync="claimLicense"
            :show-open.sync="showOpen"
            :type-ownership.sync="typeOwnership"
          />
          
          <b-button
            style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;"
            class="float-right"
            variant="primary"
            @click.prevent="submitEditar"
            :disabled="itemVerify === 'active' ? false : true"
            v-if="typeOwnership === false"
          >
            <span
              class=""
              style="font-weight: 700; color: #FFFFFF;"
            >{{typeOwnership === true || typeOwnership === 'true' ? 'Claim site' : typeOwnership === false ? 'Claim site' : 'Claim site'}}</span>
          </b-button>
        </div>
        <help-footer :url="idsite.domain" />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BLink,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import HelpFooter from '@/views/components/footer/HelpFooter'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import Urlbox from 'urlbox'
import { db, auth, storage, dbErrors, firebase } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BLink,
    ClaimOwnership,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpen',
    event: 'update:show-open',
  },
  props: ['idsite', 'showOpen', 'typeOwnership'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      imgSite: '',
      keyID: '',
      claimLicense: false,
      itemVerify: localStorage.getItem('verify'),
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
    }
  },
  watch: {
    // idsite(val) {
      // console.log(val)
    //   if(val) {
    //     this.userData = {}
    //     const ref = db.collection('Users').doc(val)
    //     ref.onSnapshot(doc => {
    //       this.usuarios = {}
    //       this.usuarios = {
    //           id: doc.data().uid,
    //           fullName: doc.data().nombre,
    //           firstName: doc.data().firstName ? doc.data().firstName : '',
    //           lastName: doc.data().lastName ? doc.data().lastName : '',
    //           email: doc.data().email,
    //           role: doc.data().role,
    //           avatar: doc.data().foto,
    //           contact: doc.data().contact ? doc.data().contact : '',
    //           permissions: doc.data().permissions ?  doc.data().permissions : [],
    //       }
    //       this.emailOld = doc.data().email
    //       this.userData = this.usuarios
    //     })
    //   }
    // },
    showOpen(val) {
      localStorage.removeItem('verify')
      if(val) {
        document.body.style.overflow = 'hidden'

        const user = auth.currentUser
        if(user) {
          db.collection('Users').doc(user.uid).get()
          .then(userDoc => {
            const getFirstUser = userDoc.id.slice(0, 4);
            const getFirstSite = this.$route.params.id.slice(0, 4);
            // const getFirstProject = this.$route.params.project.slice(0, 3);
            this.keyID = getFirstUser + getFirstSite

            var obj = {
              "author": userDoc.data().email,
              "css": "",
              "domain": this.idsite.domain.includes('https://') || this.idsite.domain.includes('http://') ? this.idsite.domain : this.idsite.domainFix,
              "key": this.keyID,
              "mode": 0
            }
            // console.log(this.idsite,obj)

            var config = {
              method: 'POST',
              url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
              headers: {
                'Content-Type': 'application/json',
              },
              data: JSON.stringify(obj),
            }

            this.axios(config)
            .then(response => {
              console.log('Create fix file: '+response.data.status)
              // this.verifyFunction()
            })
            .catch(e => console.log(e.message))
          })
        }

        var getClaimLicense = localStorage.getItem('configRunLicense')
        if(getClaimLicense) {
          this.claimLicense = true
        }
        // console.log(this.idsite.projectid)

        // setTimeout(() => {
        //   if(this.idsite.id) {
        //     db.collection('Sites').doc(this.idsite.id).get()
        //     .then(siteDoc => {
        //       if(siteDoc.exists) {
        //         // console.log(siteDoc.data().homepage)
        //         // Plugin your API key and secret
        //         const urlbox = Urlbox('iWVAo1U5wUbLhzWJ', 'c3e32765daba406cb27d1ad22ed93b6d');
                
        //         // Set your options
        //         const options = {
        //           url: siteDoc.data().homepage,
        //           // thumb_width: 1440,
        //           width: 1440,
        //           height: 840,
        //           format: "jpg",
        //           quality: 70,
        //           delay: 500
        //         };
                
        //         const urlImgUrl = urlbox.buildUrl(options)

        //         var obj = {
        //           url: urlImgUrl
        //         }
        //         console.log(urlImgUrl)
        //         var config = {
        //           method: 'POST',
        //           url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
        //           headers: {
        //             'Content-Type': 'application/json',
        //           },
        //           data: JSON.stringify(obj),
        //         }

        //         this.axios(config)
        //         .then(async response => {
        //           // console.log(response.data)
        //           const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}siteImg`)
        //           const upl = await refImg.putString(response.data, 'data_url')
        //           const urlDescarga = await refImg.getDownloadURL()
        //           // this.imgSite = urlDescarga
        //           // console.log(urlDescarga)

        //           // update thumbnail
        //           db.collection('Sites').doc(siteDoc.id).update({
        //             thumbnail: urlDescarga
        //           })

        //           db.collection('Sites_cache').doc(siteDoc.id).update({
        //             thumbnail: urlDescarga
        //           })

        //           db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(siteDoc.id).update({
        //             thumbnail: urlDescarga
        //           })

        //           this.idsite.imgSite = urlDescarga
        //         })
        //         .catch(error => {
        //           console.log(error)
        //         })
        //       }
        //     })
        //   }
        // }, 1500)
      } else {
        localStorage.removeItem('configRunLicense')
        this.claimLicense = false
        document.body.style.overflow = 'auto'
      }
    }
  },
  validations: {
  },
  methods: {
    verifyFunction() {
      var obj = {
        "url": this.idsite.domain,
        "scriptSrc": `https://cdn.seocloud.ai/${this.keyID}`
      }

      var config = {
        method: 'POST',
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/check-script',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(obj),
      }

      this.axios(config)
      .then(response => {
        console.log(response.data)
        if(!response.data.scriptPresent) {
          // this.counterTriesVerify >= 3 ? this.counterTriesVerify = 1 : this.counterTriesVerify += 1
          // this.verifying = false
          // this.processVerificationFail = true
        } else {
          // this.processVerificationFail = false
          // this.counterTriesVerify = 0
          console.log('exist file in html')
          setTimeout(() => {
            // this.verifying = false
            // this.processVerificationFail = true
            // this.processVerificationSuccess = true
            localStorage.setItem('verify', 'active')

            const user = auth.currentUser
            if(user) {
              db.collection('Users').doc(user.uid).get()
              .then(userDoc => {
                var userData = {}
                userData.uid = userDoc.id
                userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
                userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
                userData.status = userDoc.data().status ? userDoc.data().status : ''
                userData.email = userDoc.data().email ? userDoc.data().email : ''
                db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.key).update({
                  owner: userData,
                  claimed: true,
                  owners: firebase.firestore.FieldValue.arrayUnion(userDoc.data().uid)
                })
                .then(() => {
                  db.collection('Sites').doc(this.idsite.key).get()
                  .then(docSite => {
                    // edit and update fixes
                    dbErrors.collection('fixes').where('SiteID', '==', docSite.id).get()
                    .then(docPageFixCollection => {
                      dbErrors.collection('fixes_cache').where('SiteID', '==', docSite.id).limit(1).get()
                      .then(docPageFixCacheCollection => {
                        // console.log(docPageFixCollection.size,docPageFixCacheCollection.size)
                        if(docPageFixCollection.size) {
                          if(docPageFixCacheCollection.size) {
                            var arrayFixes = []
                            docPageFixCollection.forEach(docFix => {
                              if(docFix.data().changes && docFix.data().changes.length) {
                                var filterFixesOwner = docFix.data().changes.filter(item => item.user === userDoc.id)
                                if(filterFixesOwner.length) {
                                  // order by the last fix
                                  filterFixesOwner.sort((a, b) => b.idfix - a.idfix)
                                  filterFixesOwner.forEach(element => {
                                    var filterchangesFixesCache = arrayFixes.filter(ele => ele.id === element.id && ele.idError === element.idError && ele.url === element.url)
                                    if(filterchangesFixesCache.length === 0) {
                                      if(element.idError === 8 || element.idError === 14 || element.idError === 3) {
                                        arrayFixes.push({
                                          id: element.id,
                                          idError: element.idError,
                                          typeError: element.typeError,
                                          old: element.old,
                                          fix: element.fix,
                                          url: element.url,
                                          urlImg: element.urlImg,
                                          date: element.date,
                                          idfix: element.idfix
                                        })
                                      } else {
                                        arrayFixes.push({
                                          id: element.id,
                                          idError: element.idError,
                                          typeError: element.typeError,
                                          old: element.old,
                                          fix: element.fix,
                                          url: element.url,
                                          date: element.date,
                                          idfix: element.idfix
                                        })
                                      }
                                    }
                                  })
                                }
                              }
                            })

                            docPageFixCacheCollection.forEach(docFixCache => {
                              dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                                changes: arrayFixes,
                                owner: userDoc.id,
                                ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                                updated: Date.now()
                              })
                              .then(() => {
                                console.log('update fixes owner')
                                // if(!this.claimLicense && arrayFixes.length >= 1) {
                                //   localStorage.setItem('configRunLicenseOwner', true)
                                // }
                              })
                              .catch((err) => console.log('err fixes owner', err.message))
                            })
                          } else {
                            dbErrors.collection('fixes_cache').add({
                              SiteID: docSite.id,
                              owner: userDoc.id,
                              ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                              changes: [],
                              updated: Date.now()
                            })
                          }
                        } else {
                          if(docPageFixCacheCollection.size === 0) {
                            dbErrors.collection('fixes_cache').add({
                              SiteID: docSite.id,
                              owner: userDoc.id,
                              ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                              changes: [],
                              updated: Date.now()
                            })
                          } else {
                            var arrayFixes = []
                            
                            docPageFixCacheCollection.forEach(docFixCache => {
                              dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                                changes: arrayFixes,
                                owner: userDoc.id,
                                ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                                updated: Date.now()
                              })
                              .then(() => {
                                console.log('update fixes owner')
                                // if(!this.claimLicense && arrayFixes.length >= 1) {
                                //   localStorage.setItem('configRunLicenseOwner', true)
                                // }
                              })
                              .catch((err) => console.log('err fixes owner', err.message))
                            })
                          }
                        }
                      })
                    })

                    var getExternalLinks = docSite.data().externalLinks && docSite.data().externalLinks.length ? docSite.data().externalLinks : []
                    var newExternalLinks = []
                    var counterExternal = 0
                    getExternalLinks.forEach(eleExternal => {
                      counterExternal += 1
                      if(counterExternal <= 50) {
                        newExternalLinks.push(eleExternal)
                      }
                    })

                    db.collection('Sites').doc(this.idsite.key).update({
                      externalLinks: newExternalLinks,
                      owner: userData,
                      claimed: true,
                      owners: firebase.firestore.FieldValue.arrayUnion(userDoc.data().uid)
                    })

                    // if(this.claimLicense) {
                    //   localStorage.setItem('configRunLicenseOwner', true)
                    // }

                    this.$refs.sidebar_site_ownership.hide()
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Site verified!',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                      },
                    })
                    // }
                  })
                })
              })
            }
          }, 10000)
        }
      })
      .catch(e => {
        console.log(e.message)
      })
    },
    submitEditar() {
      this.claimLicense = false
      this.$refs.sidebar_site_ownership.hide()
    },
    getVerifyStatus() {
      this.itemVerify = localStorage.getItem('verify')
    }
  },
  mounted() {
    this.claimLicense = false
    this.events.forEach((event) => {
      this.itemVerify = window.addEventListener(event, this.getVerifyStatus)
    })
  },
  destroyed() {
    this.events.forEach((event) => {
        window.removeEventListener(event, this.getVerifyStatus)
    })
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-site-ownership {
    width: 90%;
    max-width: 593px !important;
}
.link_claim:hover {
  color: #a7aab9 !important;
}
// @media (min-width: 769px) {
// }
@media (max-width: 768px) {
  .container-body-config-claim {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
